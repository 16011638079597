.@{picker-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-suffix {
    .@{picker-prefix-cls}-rtl & {
      margin-right: @padding-xs / 2;
      margin-left: 0;
    }
  }

  &-clear {
    .@{picker-prefix-cls}-rtl & {
      right: auto;
      left: 0;
    }
  }

  &-separator {
    .@{picker-prefix-cls}-rtl & {
      transform: rotate(180deg);
    }
  }

  &-header {
    &-view {
      button {
        &:not(:first-child) {
          .@{picker-prefix-cls}-panel-rtl & {
            margin-right: @padding-xs;
            margin-left: 0;
          }
        }
      }
    }
  }

  // ======================== Range =========================
  &-range {
    // Clear
    .@{picker-prefix-cls}-clear {
      .@{picker-prefix-cls}-rtl& {
        right: auto;
        left: @input-padding-horizontal-base;
      }
    }

    // Active bar
    .@{picker-prefix-cls}-active-bar {
      .@{picker-prefix-cls}-rtl& {
        margin-right: @input-padding-horizontal-base;
        margin-left: 0;
      }
    }

    &.@{picker-prefix-cls}-small {
      .@{picker-prefix-cls}-active-bar {
        .@{picker-prefix-cls}-rtl& {
          margin-right: @input-padding-horizontal-sm;
        }
      }
    }
  }

  // ======================== Ranges ========================
  &-ranges {
    .@{picker-prefix-cls}-dropdown-rtl & {
      text-align: right;
    }

    .@{picker-prefix-cls}-ok {
      .@{picker-prefix-cls}-dropdown-rtl & {
        float: left;
        margin-right: @padding-xs;
        margin-left: 0;
      }
    }
  }

  // ======================== Panel ========================
  &-panel {
    &-rtl {
      direction: rtl;
    }
  }

  &-prev-icon,
  &-super-prev-icon {
    .@{picker-prefix-cls}-panel-rtl & {
      transform: rotate(135deg);
    }
  }

  &-next-icon,
  &-super-next-icon {
    .@{picker-prefix-cls}-panel-rtl & {
      transform: rotate(-45deg);
    }
  }

  &-cell {
    .picker-cell-inner(~'@{picker-cell-inner-cls}');
  }

  // ======================== Body ==========================
  .picker-cell-inner(@cellClassName) {
    .@{cellClassName} {
      position: relative;
      z-index: 2;
      display: inline-block;
      min-width: @picker-panel-cell-height;
      height: @picker-panel-cell-height;
      line-height: @picker-panel-cell-height;
      border-radius: @border-radius-base;
      transition: background @animation-duration-slow, border @animation-duration-slow;
    }

    &-in-view&-range-start::before {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 50%;
        left: 0;
      }
    }

    &-in-view&-range-end::before {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 0;
        left: 50%;
      }
    }

    &-in-view&-range-start&-range-end::before {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 50%;
        left: 50%;
      }
    }

    .@{picker-prefix-cls}-date-panel
      &-in-view&-in-range&-range-hover-start
      .@{cellClassName}::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 0;
        left: -5px - @border-width-base;
      }
    }

    .@{picker-prefix-cls}-date-panel &-in-view&-in-range&-range-hover-end .@{cellClassName}::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: -5px - @border-width-base;
        left: 0;
      }
    }

    // Hover with range start & end
    &-range-hover&-range-start::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 0;
        left: 50%;
      }
    }

    &-range-hover&-range-end::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 50%;
        left: 0;
      }
    }

    // range start border-radius
    &-in-view&-range-start:not(&-range-start-single):not(&-range-end) .@{cellClassName} {
      .@{picker-prefix-cls}-panel-rtl & {
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }
    }

    // range end border-radius
    &-in-view&-range-end:not(&-range-end-single):not(&-range-start) .@{cellClassName} {
      .@{picker-prefix-cls}-panel-rtl & {
        border-radius: @border-radius-base 0 0 @border-radius-base;
      }
    }

    // Edge start
    tr > &-in-view&-range-hover:not(&-selected):first-child::after,
    &-in-view&-start&-range-hover-edge-start&-range-hover-edge-start-near-range::after,
    &-in-view&-range-hover-edge-start:not(&-range-hover-edge-start-near-range)::after,
    &-in-view&-range-hover-start::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 6px;
        left: 0;
        border-right: @border-width-base dashed @picker-date-hover-range-border-color;
        border-left: none;
        border-top-left-radius: 0;
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
        border-bottom-left-radius: 0;
      }
    }

    // Edge end
    tr > &-in-view&-range-hover:not(&-selected):last-child::after,
    &-in-view&-end&-range-hover-edge-end&-range-hover-edge-end-near-range::after,
    &-in-view&-range-hover-edge-end:not(&-range-hover-edge-end-near-range)::after,
    &-in-view&-range-hover-end::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 0;
        left: 6px;
        border-right: none;
        border-left: @border-width-base dashed @picker-date-hover-range-border-color;
        border-top-left-radius: @border-radius-base;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: @border-radius-base;
      }
    }

    tr > &-in-view&-range-hover-start:last-child::after,
    tr > &-in-view&-range-hover-end:first-child::after,
    &-in-view&-start&-range-hover-edge-start:not(&-range-hover)::after,
    &-in-view&-start&-range-hover-end&-range-hover-edge-start:not(&-range-hover)::after,
    &-in-view&-end&-range-hover-start&-range-hover-edge-end:not(&-range-hover)::after,
    tr > &-in-view&-start&-range-hover&-range-hover-edge-start:last-child::after,
    tr > &-in-view&-end&-range-hover&-range-hover-edge-end:first-child::after {
      .@{picker-prefix-cls}-panel-rtl & {
        right: 6px;
        left: 6px;
        border-right: @border-width-base dashed @picker-date-hover-range-border-color;
        border-left: @border-width-base dashed @picker-date-hover-range-border-color;
        border-radius: @border-radius-base;
      }
    }
  }

  // ======================== Footer ========================
  &-footer {
    &-extra {
      .@{picker-prefix-cls}-dropdown-rtl & {
        direction: rtl;
        text-align: right;
      }
    }
  }

  // ====================== Time Panel ======================
  &-time-panel {
    .@{picker-prefix-cls}-panel-rtl & {
      direction: ltr;
    }
  }
}
